header#main-header {

  border-bottom: 1px solid #dddddd;
  overflow: hidden;
  margin: 0 0px 10px 0px;
  position: relative;


}

header#main-header .header-content {

  background-color: #ffffff;
  min-height: 72px;
  display: flex;
  flex-direction: row;
  font-size: calc(10px + 2vmin);
  color: white;
  align-items: center;
  justify-content: space-between;
  justify-items: center;
  align-content: center;
  
  margin: 0 auto;
  padding: 0 20px;
  height: 72px;
}

@media screen and (max-width: 450px) {
  header#main-header .header-content {
    flex-direction: column;
    height: 350px;
  }
}

header#main-header .header-content a {
  text-decoration: none;
  color: #474747;
  font-family: Helvetica, 'Helvetica Neue', 'Trebuchet MS', sans-serif;
  font-style: normal;
  font-size: 18px;
  font-weight: normal;
  padding-top: 17px;
}