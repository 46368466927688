.container1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-items: center;
  align-items: center;
  align-content: center;
  border-bottom: 1px solid #dddddd;
  border-top: 1px solid #dddddd;
}

.container2 {
  display: flex;
  flex-direction: column;
}

.texto1 {
  text-align: center;
  font-size: 25px;
}

.texto2 {
  text-align: left;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  max-height: 10px;
  max-width: 260px;
  padding-bottom: 100%;
}

.texto3 {
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
}

.fotos {
  width: 280px;
  height: 220px;
}

@media screen and (max-width: 450px) {
  .container1 {
    flex-direction: column;
    width: 300px;
  }
  .container2 {
    flex-direction: column;
    width: 300px;
  }
  .texto1 {
    width: 300px;
  }
  .texto2 {
    width: 300px;
  }
  .texto3 {
    width: 300px;
  }
}