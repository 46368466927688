.galeria {
  display: flex;
  width: 100%;
  height: 150px;
  position: absolute;
  overflow: hidden;
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  margin: auto;

}

.foto {
  position: absolute;
  max-width: 250px;
  max-height: 140px;
  width: auto;
  height: auto;
  padding: 4%;
  animation: animacao 40s infinite;
  opacity: 0;
  align-items: center;
  align-content: center;
  justify-items: center;
  justify-content: center;
  margin: auto;
}

@keyframes animacao {
  12.5% {
    opacity: 1;
    transform: scale(1.1, 1.1);
  }
  20% {
    opacity: 0;
  }
}

.foto:nth-child(1) {
  animation-delay: 0s;
}

.foto:nth-child(2) {
  animation-delay: 5s;
}

.foto:nth-child(3) {
  animation-delay: 10s;
}

.foto:nth-child(4) {
  animation-delay: 15s;
}

.foto:nth-child(5) {
  animation-delay: 20s;
}

.foto:nth-child(6) {
  animation-delay: 25s;
}

.foto:nth-child(7) {
  animation-delay: 30s;
}

.foto:nth-child(8) {
  animation-delay: 35s;
}