.content {
    display: flex;
    justify-content: center;
    overflow: auto;
    margin: auto;
}

.contato {
    width: 100%;
    max-width: 500px;
    overflow: auto;
}

.form {
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.field {
    padding: 1px;
    margin-bottom: 15px;
    border: 1px solid #DDD;
    border-radius: 5px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    width: 400px;
    overflow: scroll;
}

.textarea {
    height: 150px;
    width: 450px;
    overflow: auto;
}

@media screen and (max-width: 450px) {
    .form {
        width: 300px;
    }
    .field {
        width: 300px;
    }
    .textarea {
        width: 280px;
    }
    
  }