.imagens {
  display: flex;
  flex-direction: column;
}

.texto1 {
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  margin: auto;
}