.container1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-items: center;
  align-items: center;
  align-content: center;
  border-bottom: 1px solid #dddddd;
  border-top: 1px solid #dddddd;
}

.container2 {
  display: flex;
  flex-direction: column;
  
}

.texto1 {
  text-align: center;
  font-size: 25px;
}

.texto2 {
  text-align: left;
  font-size: 18px;
  max-height: 10px;
  max-width: 260px;
  padding-bottom: 100%;
}

.texto3 {
  text-align: center;
  font-size: 18px;
}

.texto4 {
  padding-top: 10%;
  text-align: center;
  font-size: 18px;
}

.texto5 {
  padding-top: 5%;
  text-align: center;
  font-family: 14px Arial, Helvetica, sans-serif;
  color: #757575;
  font-weight: normal;
}

.sublinhado {
  padding-top: 5%;
  text-align: center;
  font-family: 14px Arial, Helvetica, sans-serif;
  color: #757575;
  font-weight: normal;
  text-decoration: underline;
}

@media screen and (max-width: 450px) {
  .container1 {
    flex-direction: column;
  }
  .container2 {
    flex-direction: column;
  }
  .texto1 {
    width: 300px;
  }
  .texto2 {
    width: 300px;
  }
  .texto3 {
    width: 300px;
  }
  .texto4 {
    width: 300px;
  }
  .texto5 {
    width: 300px;
  }
}