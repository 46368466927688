* {
  margin: auto;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  max-width: 880px;
  margin: 17px auto;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;

  justify-items: center;
  justify-content: center;
  align-content: center;
  align-items: center;
  text-align: center;
  text-justify: distribute;


}

body {
  margin: auto;
  background: #ffffff;
  font: 14px sans-serif;
  -webkit-font-smoothing: antialiased !important;

  justify-items: center;
  justify-content: center;
  align-content: center;
  align-items: center;
  text-align: center;
  text-justify: distribute;
}

